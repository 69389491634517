<template>
  <div>
    <div id="app">
      <img src="/column.png" alt="">
      <div class="container">
        <router-view/>
      </div>
      <div class="legals" id="dsq">
        <a href="https://medias-storage.blackmoonlab.fr/novartis/cguF.pdf" target="_blank">CGU</a>
        <br>
        <a href="https://medias-storage.blackmoonlab.fr/novartis/noticeF.pdf" target="_blank">Notice Web</a>
        <br>
        <a href="#" id="custom-ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</a>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBar from "./components/HeadBar";
export default {
  components: {HeadBar},
  async mounted(){
    this.gggg()
    const channelConfig = this.$pusher.subscribe(this.$appName+'-config')
    channelConfig.bind('change', (data) => {
      this.$store.dispatch('setConfig', data)
    })
    const channelState = this.$pusher.subscribe(this.$appName+'-state')
    channelState.bind('change', (data) => {
      this.$store.dispatch('setState', data)
      if(data == 'postshow'){
        this.$router.push('/login')
      }
    })
  },
  methods: {
    async gggg(){
      let { data } = await this.$axios.get('/config/getConfig.php')
      this.$store.dispatch('setConfig', data)
      let response = await this.$axios.get('/state/getState.php')
      this.$store.dispatch('setState', response.data.stateValue)
      if(response.data.stateValue == 'postshow'){
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    font-family: sans-serif;
  }

  body {
    width: 100vw;
    overflow-x: hidden;
  }

  #app {
    display: flex;
    width: 100vw;
    height: 100vh;

    >img {
      height: 100%;
      object-fit: contain;
    }

    .container {
      height: 100vh;
      flex-grow: 1;
      overflow-y: scroll;
      width: 100%;
      overflow-x: hidden;
    }
  }

  .container {
    width: 80vw;
    min-height: 88vh;
    margin: 0 auto;
  }

  $blue: #1A2A6C;
  $red: #B21F1F;
  $orange: #D15F25;
  $yellow: #FDBB2D;

  .button {
    padding: 5px 10px;
    border: solid 2px $blue;
    text-align: center;
    font-weight: 700;
    color: $blue;
    border-radius: 100px;
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .button:hover {
    color: white;
    background-color: $blue;
  }

  .legals {
    position: fixed;
    bottom: 15px;
    left: 15px;
    a {
      color: white;
      text-decoration: none;
      font-size: 12px;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  #ffg {
    display: none;
  }

  @media screen and (max-width: 1024px){
    #ffg {
      width: 100%;
      height: 70px;
      background-color: #0060AF;
      margin-top: 30px;
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    #dsq {
      display: none;
    }

    .legals {
      position: unset;
      top: unset;
      left: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      a:first-child {
        margin: 0;
      }
    }

    #app {

      > img {
        display: none;
      }
    }
  }

</style>
