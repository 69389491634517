<template>
  <div class="home">
    <header>
      <img src="/novartis.png" alt="">
      <div>
        <p class="title">{{ $store.getters.config.title }}</p>
        <p class="subtitle">{{ $store.getters.config.subtitle }}</p>
      </div>
    </header>
    <main>
      <div class="top">
        <p></p>
        <p>{{ $store.getters.config.live_dt }}</p>
      </div>
      <div class="top bottom-one">
        <div>
          <p>Bienvenue {{$store.getters.user.first_name}} {{$store.getters.user.name}}</p>
        </div>
        <div class="deco" @click="logout">
          <img src="/logout.svg" alt=""> Déconnexion
        </div>
      </div>
      <div class="content">
        <div class="video-box">
          <template v-if="$store.getters.state == 'show'">
            <iframe :src="$store.getters.config.stream_link" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </template>
          <template v-if="$store.getters.state == 'replay'">
            <iframe :src="$store.getters.config.stream_link" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </template>
          <template v-if="$store.getters.state == 'preshow'">
            <!--<div class="preshow">
              <p>Pour suivre cet évènement, rendez-vous le</p>
              <p class="date">{{ $store.getters.config.preshow_dt }}</p>
              <div class="li">
                <p>Vous pouvez d'ores et déjà :</p>
                <ul>
                  <li>Poser vos questions en amont de l’événement</li>
                  <li>Consulter l’agenda</li>
                  <li>Voir les intervenants</li>
                  <li>Accéder à des documents</li>
                </ul>
              </div>
            </div>-->
            <img :src="'https://medias-storage.blackmoonlab.fr' + $store.getters.config.preshow_dt" alt="">
          </template>
        </div>
        <div class="bottom-bar" id="hdg">
          <div class="bbLeft">
            <img src="/question_mark.png" alt="" @click="openSlido">
            <p @click="openSlido">Répondez aux sondages ici</p>
          </div>
          <div class="bbRight" @click="() => openInNewTab($store.getters.config.mood_link)">
            <p>Votre avis nous intéresse, répondez au questionnaire de satisfaction en cliquant ici.</p>
          </div>
        </div>
        <div class="activity-box">
          <div class="tabs">
            <img :src="panel == 'agenda' ? '/calendarYellow.svg' : '/calendarBlue.svg'" @click="() => panel = 'agenda'" alt="">
            <img :src="panel == 'chat' ? '/questionAnswerYellow.svg' : '/questionAnswerBlue.svg'" @click="() => panel = 'chat'" alt="">
            <img :src="panel == 'speaker' ? '/personYellow.svg' : '/personBlue.svg'" @click="() => panel = 'speaker'" alt="">
          </div>
          <div class="panel agenda" v-if="panel == 'agenda'">
            <div class="day" v-for="day in agenda" :key="day.idDay">
              <p class="dayTitle">{{ day.day }}</p>
              <div class="events">
                <div class="event" v-for="event in day.events" :key="event.idEvent">
                  <p class="hours">{{ event.hours }}</p>
                  <div>
                    <p class="atitle">{{ event.title }}</p>
                    <p class="asubtitle">{{ event.subtitle }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel chat" v-if="panel == 'chat'">
            <div class="messages" style="width: 100%;">
              <Message v-for="message in messages" :message="message" :key="message.idMessage"/>
            </div>
            <div>
              <div class="input-box">
                <div class="aaa">
                  <input type="text" v-model="message">
                  <img @click="postMessage" src="/send.svg" alt="">
                </div>
                <div style="text-align: center; font-size: 10px; color: #AAAAAA; padding-top: 10px;">Merci de ne pas utiliser ce chat pour des discussions mais uniquement pour adresser vos questions au modérateur. Il y aura un temps dédié pour y répondre.</div>
              </div>
            </div>
          </div>
          <div class="panel speakers" v-if="panel == 'speaker'">
            <div class="speaker" v-for="speaker in speakers" :key="speaker.idSpeaker">
              <img :src="'https://medias-storage.blackmoonlab.fr'+speaker.img_url" alt="">
              <div>
                <p class="name">{{ speaker.name }}</p>
                <p v-html="speaker.bio" class="bio"></p>
              </div>
            </div>
          </div>
          <div class="panel documents" v-if="panel == 'document'">
            <a target="_blank" v-for="document in documents" :key="document.idDocument" class="document" :href="'https://medias-storage.blackmoonlab.fr'+document.url">
              <img :src="document.type == 'image' ? '/image.svg' : '/pdf.svg'" alt="">
              <div>
                <p class="dtitle">{{ document.title }}</p>
                <p class="dsubtitle">{{ document.subtitle }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="bottom-bar" id="dgh">
        <div class="bbLeft">
          <img src="/question_mark.png" alt="" @click="openSlido">
          <p @click="openSlido">Répondez aux sondages ici</p>
        </div>
        <div class="bbRight" @click="() => openInNewTab($store.getters.config.mood_link)">
          <p>Votre avis nous intéresse, répondez au questionnaire de satisfaction en cliquant ici.</p>
        </div>
      </div>
      <div id="ffg">
        <div class="legals">
          <a href="https://medias-storage.blackmoonlab.fr/novartis/cguF.pdf" target="_blank">CGU</a>
          <br>
          <a href="https://medias-storage.blackmoonlab.fr/novartis/noticeF.pdf" target="_blank">Notice Web</a>
          <br>
          <a href="#" id="custom-ot-sdk-btn" class="ot-sdk-show-settings"> Cookie Settings</a>
        </div>
      </div>
    </main>
    <div class="slido" :style="{right: right + 'px'}">
      <div class="closeBar">
        <p @click="closeSlido">Fermer</p>
      </div>
      <iframe :src="$store.getters.config.slido_link" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import Message from "@/components/Message";
export default {
  name: "Home",
  components: {Message},
  data(){
    return {
      panel: '',
      platforms: [],
      speakers: [],
      documents: [],
      agenda: [],
      messages: [],
      message: '',
      right: -350,
      appear: 'none'
    }
  },
  mounted(){
    this.getSpeakers()
    this.getDocuments()
    this.getAgenda()
    this.getMessages()
    const likeChannel = this.$pusher.subscribe(this.$appName + '-like')
    likeChannel.bind('like', data => {
      console.log(data)
      for(let i = 0; i< this.messages.length; i++){
        if(this.messages[i].idMessage == data.idMessage){
          this.messages[i].likes.push(data)
          this.sort()
          this.messages.reverse()
        }
      }
    })
    const messageChannel = this.$pusher.subscribe(this.$appName + '-moderation')
    messageChannel.bind('moderate', data => {
      this.messages.push(data)
      this.sort()
      this.messages.reverse()
    })
    setTimeout(() => {
      switch(this.$store.getters.state){
        case 'preshow':
          this.panel = 'agenda'
          break
        case 'show':
          this.panel = "chat"
          break
        default:
          this.panel = "chat"
      }
    }, 1000)
  },
  methods: {
    async getSpeakers(){
      const { data } = await this.$axios.get('/speakers/getSpeakers.php')
      this.speakers = data
    },
    async getDocuments(){
      const { data } = await this.$axios.get('/documents/getDocuments.php')
      this.documents = data
    },
    async getAgenda(){
      const { data } = await this.$axios.get('/agenda/getAgenda.php')
      this.agenda = data
    },
    async getMessages(){
      const { data } = await this.$axios.get('/moderation/getMessages.php')
      this.messages = data
      this.sort()
      this.messages.reverse()
    },
    async postMessage(){
      if(this.message != ''){
        const { data } = await this.$axios.post('/moderation/postMessage.php', {
          message: this.message
        })
        this.message = ''
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank').focus();
    },
    logout(){
      localStorage.removeItem('token')
      this.$store.dispatch('setUser', null)
      this.$router.push('/login')
    },
    openSlido(){
      this.appear = 'block'
      this.right = 0
    },
    closeSlido(){
      this.right = -350
      setTimeout(() => {
        this.appear = 'none'
      }, 500)
    },
    sort(){
      let len = this.messages.length;
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < len; j++) {
          if(this.messages[j] != undefined && this.messages[j + 1] != undefined){
            if (this.messages[j].likes > this.messages[j + 1].likes) {
              let tmp = this.messages[j];
              this.messages[j] = this.messages[j + 1];
              this.messages[j + 1] = tmp;
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $blue: #0060AF;
  $red: #B21F1F;
  $orange: #D15F25;
  $yellow: #EC9A1E;

  #hdg {
    display: none;
  }

  header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    img {
      width: 40%;
      object-fit: contain;
    }

    .title {
      text-align: right;
      font-size: 28px;
      font-weight: 700;
      color: $blue;
      margin-bottom: 10px;
    }

    .subtitle {
      text-align: right;
      font-size: 20px;
      font-weight: 700;
      color: $blue;
      margin-bottom: 10px;
    }
  }

  main {
    width: 90%;
    margin: 0 auto;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: $blue;
    }

    .content {
      display: flex;
      margin-top: 7vh;
      justify-content: space-between;

      $videobox-width: 50vw;

      .video-box {
        width: $videobox-width;
        border: solid 2px $blue;
        height: calc(#{$videobox-width}/16*9);

        iframe {
          width: 100%;
          height: 100%;
          background-color: black;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .preshow {
          width: 100%;
          height: 100%;
          background-color: #E5EFF7;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: $blue;

          > p {
            font-size: 18px;
          }

          .date {
            font-weight: 700;
            color: $yellow;
            font-size: 30px;
            margin: 20px 0;
          }

          .li {
            p {
              margin-bottom: 20px;
            }
          }
        }
      }

      .activity-box {
        width: 30vw;
        height: calc(#{$videobox-width}/16*9);
        border: solid 2px $blue;
        display: flex;
        flex-direction: column;
      }
    }

    .bottom-one {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;

      a {
        text-decoration: none;
        color: $yellow;
      }

      .deco {
        display: flex;
        align-items: center;
        color: #818181;
        cursor: pointer;

        img {
          margin-right: 5px;
        }
      }
    }

    .bottom-two {
      margin: 25px 0;
      color: $blue;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }

  .panel {
    width: 100%;
    height: calc(100% - 35px);
  }

  .tabs {
    height: 35px;
    padding: 5px;
    border-bottom: solid 2px $blue;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      height: 20px;
      object-fit: contain;
    }
  }

  .speakers {
    overflow-y: scroll;
  }

  .speaker {
    margin: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80px;
      width: 80px;
      object-fit: contain;
      border-radius: 1000px;
      overflow: hidden;
      margin-right: 30px;
    }

    > div {
      width: calc(100% - 110px);
    }

    .name {
      font-size: 14px;
      font-weight: 700;
      color: $blue;
      margin-bottom: 10px;
    }

    .bio {
      color: #AAAAAA;
      font-size: 14px;
    }
  }

  .documents {
    overflow-y: scroll;
  }

  .document {
    margin: 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
      margin-right: 30px;
    }

    > div {
      width: calc(100% - 110px);
    }

    .dtitle {
      font-size: 14px;
      font-weight: 700;
      color: $blue;
      margin-bottom: 10px;
    }

    .dsubtitle {
      color: #AAAAAA;
      font-size: 14px;
    }
  }

  .agenda {
    overflow-y: scroll;
  }

  .day {
    margin: 30px 15px;
    > p {
      color: #AAAAAA;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .event {
      display: flex;
      margin-bottom: 15px;

      > div {
        width: 60%;
      }

      .hours {
        color: $yellow;
        margin-right: 10px;
        width: 30%;
        font-weight: 700;
      }

      .atitle {
        margin-bottom: 5px;
        color: $blue;
        font-weight: 700;

      }

      .asubtitle {
        color: $blue;
      }
    }
  }

  .chat {
    display: flex;
    flex-direction: column;

    .messages {
      flex-grow: 1;
      padding: 10px;
      overflow-y: scroll;
    }
    .input-box {

      padding: 10px;

      .aaa {
        display: flex;
        align-items: center;
      }

      input {
        flex-grow: 1;
        border: none;
        border-bottom: solid 1px #818181;
      }

      img {
        height: 20px;
        object-fit: contain;
        margin-left: 20px;
      }
    }
  }

  .bottom-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    height: 50px;

    .bbLeft {
      width: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        height: 100%;
        object-fit: contain;
        margin-right: 15px;
        cursor: pointer;
      }

      p {
        color: $blue;
        font-weight: bold;
        cursor: pointer;
      }

    }

    .bbRight {
      width: 30vw;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      cursor: pointer;

      p {
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .slido {
    width: 350px;
    height: 600px;
    position: fixed;
    top: 10%;
    border: solid 2px $blue;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    transition: right 0.5s;
    overflow: hidden;

    iframe {
      flex-grow: 1;
      width: 100%;
    }

    .closeBar {
      height: 40px;
      width: 100%;
      padding: 5px 15px;
      background-color: $blue;
      display: flex;
      align-items: center;

      p {
        text-align: right;
        font-weight: bold;
        color: white;
        cursor: pointer;
      }
    }
  }


  @media screen and (max-width: 1024px){
    #hdg {
      display: flex;
    }

    #dgh {
      display: none;
    }

    header {
      flex-direction: column;

      img {
        width: 100%;
        margin-bottom: 25px;
      }
    }

    main {
      .top {
        flex-direction: column;
      }

      .bottom-one {
        flex-direction: column;
        align-items: flex-start;

        div:first-child {
          margin-bottom: 10px;
        }
      }

      .bottom-bar {
        flex-direction: column;
        margin-bottom: 30px;
        height: unset;

        .bbLeft, .bbRight {
          width: 100%;
        }

        .bbRight {
          height: 60px;
          margin-top: 10px;
        }
      }
      .content {
        flex-direction: column;
        align-items: center;



        .video-box {
          width: 90vw;
          height: calc(90vw/16*9);
        }

        .activity-box {
          width: 90vw;
          height: 120vw;
          margin-top: 15px;
        }
      }
    }
  }

</style>