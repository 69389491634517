<template>
  <div class="mess">
    <div class="message">
      <p class="body">{{message.message}}</p>
      <div>
        <img @click="like" :src="uhl ? '/heartFull.svg' : '/heartEmpty.svg'" alt="">
        <span>{{ message.likes.length }}</span>
      </div>
    </div>
    <p class="infoUser">{{ message.first_name }} {{ message.name }} - {{ message.country }}</p>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: ['message'],
  data(){
    return {
      uhl: false
    }
  },
  mounted() {
    this.userHasLiked()
    setInterval(() => {
      this.userHasLiked()
    }, 300)
  },
  methods: {
    userHasLiked(){
      for(let like of this.message.likes){
        if(like.idUser == this.$store.getters.user.idUser){
          this.uhl = true
          return
        }
      }
      this.uhl = false
    },
    async like(){
      if(this.uhl == false){
        await this.$axios.post('/moderation/like.php', {
          app: this.$appName,
          idMessage: this.message.idMessage
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  .mess {
    margin-bottom: 15px;
  }

  .infoUser {
    color: #0060AF;
    font-size: 10px;
  }
  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #DEDEDE;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 3px;

    > div {
      display: flex;
      align-items: center;
      
      img {
        cursor: pointer;
      }

      span {
        color: #0060AF;
        font-weight: 700;
        margin-left: 5px;
      }
    }
  }
</style>